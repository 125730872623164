import type { User, UserLoginRequest, UserLoginResponse, UserPasswordRequest } from '../../models'
import { jwtAxios } from '../generic'
import { UserService } from '../user'

export class AuthService {
  userService: UserService

  constructor () {
    this.userService = new UserService()
  }

  async login (form: UserLoginRequest) : Promise<UserLoginResponse | undefined> {
    const result = await jwtAxios.post('/auth/login', form)
    if (result.data?.data) {
      return {
        token: result.data.data.access_token,
        user: this.userService.transformItem(result.data.data.user)
      }
    }
  }

  async fetchInfo () : Promise<User | undefined> {
    const result = await jwtAxios.get('/auth/me')
    return this.userService.transformItem(result.data?.data)
  }

  async changePassword (form: UserPasswordRequest) {
    return jwtAxios.put('/auth/password', { old_password: form.oldPassword, new_password: form.newPassword })
  }

  async logout () {
    return jwtAxios.get('/auth/logout')
  }
}
