import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Setting'
import Common from './Common'

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common
  })
export default reducers
