import type { Config } from '../../models'
import { jwtAxios } from '../generic'

export class ConfigService {
  async fetchAll () : Promise<Config | undefined> {
    const result = await jwtAxios.get('/config')
    return result?.data?.data
  }

  async store (config: Config) : Promise<Config | undefined> {
    const result = await jwtAxios.put('/config', config)
    return result?.data?.data
  }
}
