import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Form, Input } from 'antd'

import { AppAnimateGroup, AppPageMetadata } from '../../../@crema/core'
import IntlMessages from '../../../@crema/utility/IntlMessages'
import { ReactComponent as Logo } from '../../../assets/icon/comingsoon.svg'

import '../index.style.less'

const ComingSoon = () => {
  const { messages } = useIntl()
  const onFinish = (values) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <AppAnimateGroup type='bottom'>
      <AppPageMetadata title='Coming Soon' />
      <div className='error-container' key='coming_soon'>
        <div className='error-img-lg'>
          <Logo />
        </div>
        <div>
          <div className='error-content'>
            <h3>
              <IntlMessages id='error.comingSoon' />!
            </h3>
            <div className='error-para'>
              <p className='mb-0'>
                <IntlMessages id='error.comingSoon.message1' />
              </p>
              <p className='mb-0'>
                <IntlMessages id='error.comingSoon.message2' />
              </p>
            </div>
          </div>
          <div className='error-form-coming'>
            <Form
              className='error-form'
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}>
              <Form.Item
                name='email'
                className='form-field'
                rules={[
                  { required: true, message: 'Please enter Email Address!' }
                ]}>
                <Input placeholder={messages['common.emailAddress']} />
              </Form.Item>

              <Button type='primary' className='error-btn' htmlType='submit'>
                <IntlMessages id='error.comingSoon.notifyMe' />
              </Button>
            </Form>
          </div>
        </div>
        {/* <AppInfoView /> */}
      </div>
    </AppAnimateGroup>
  )
}

export default ComingSoon
