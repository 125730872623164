import { useQuery, useQueryClient } from 'react-query'

import type { Lecture, LectureWithContentRequest } from '../../models'
import type { ListParams } from '../generic'
import { LectureService } from './service'
import type { LecturesListResult } from './service'

export const QN_LECTURES_LIST = 'lectures-list'
export const QN_LECTURE_DETAIL = 'lecture-detail'

export const useLecturesList = (params?: ListParams) => {
  const service = new LectureService(params)
  return useQuery<LecturesListResult | undefined, Error>(
    [QN_LECTURES_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}

export const useLecture = (id: string) => {
  const service = new LectureService()
  return useQuery<Lecture | undefined, Error>(
    [QN_LECTURE_DETAIL, id],
    () => service.fetchById(id),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useLectureService = () => {
  const service = new LectureService()
  const queryClient = useQueryClient()

  const store = async (lecture: LectureWithContentRequest) : Promise<Lecture | undefined> => {
    const newLecture = await service.store(lecture)
    await queryClient.invalidateQueries(QN_LECTURES_LIST)
    return newLecture
  }

  const update = async (lecture: LectureWithContentRequest) : Promise<Lecture | undefined> => {
    const updatedLecture = await service.update(lecture)
    setTimeout(async () => {
      await queryClient.invalidateQueries([QN_LECTURE_DETAIL, lecture.id])
    }, 500)
    await queryClient.invalidateQueries(QN_LECTURES_LIST)
    return updatedLecture
  }

  const storeOrUpdate = async (lecture: LectureWithContentRequest) : Promise<Lecture | undefined> => {
    return lecture.id ? update(lecture) : store(lecture)
  }

  const updateOrder = async (courseId: string, ids: string[]) => {
    await service.updateOrder(courseId, ids)
    queryClient.invalidateQueries([
      QN_LECTURES_LIST,
      {
        filters: { course_id: courseId },
        sorter: [{ field: 'order', type: 'asc' }]
      }
    ])
  }

  const remove = async (id: string) => {
    await service.remove(id)
    queryClient.invalidateQueries(QN_LECTURES_LIST)
  }

  return { store, update, storeOrUpdate, updateOrder, remove }
}
