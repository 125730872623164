import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'

import { useT } from './string'

export const displayNotification = (type: 'error' | 'info' | 'success' | 'warning' = 'info', description? : string, message? : string) =>
  notification[type]({
    message: message || (type.charAt(0).toUpperCase() + type.slice(1)),
    description
  })

export const useErrorNotification = (error?: Error) => {
  const navigate = useNavigate()
  const t = useT()
  useEffect(() => {
    if (error) {
      if (error.message === 'server.Not found') return navigate('/error/404', { replace: true })
      if (error.message === 'server.Forbidden') return navigate('/error/403', { replace: true })
      return displayNotification('error', t(error.message))
    }
  }, [error])
}
