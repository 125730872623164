import type { DashboardSummary } from '../../models'
import { jwtAxios } from '../generic'

export class DashboardService {
  async summary () : Promise<DashboardSummary[] | undefined> {
    const result = await jwtAxios.get('/dashboard/summary')
    if (result?.data?.data) {
      return result.data.data.map(({ label, value }: DashboardSummary) => ({
        label: label.replace(/(_\w)/g, k => k[1].toUpperCase()),
        value
      }))
    }
  }
}
