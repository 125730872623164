import type { Question, QuestionResponse } from '../../models'
import { ListService, jwtAxios } from '../generic'
import type { ListResult } from '../generic'

export interface QuestionsListResult extends ListResult {
  data: Question[];
}

export class QuestionService extends ListService {
  result?: QuestionsListResult

  #prepareRequestBody (question: Question) {
    const { lectureId, ...questionForm } = question
    return {
      data: {
        ...questionForm,
        lecture_id: lectureId
      }
    }
  }

  async fetchList () : Promise<QuestionsListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/question?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map((item : QuestionResponse) => this.transformItem(item))
      return retval
    }
  }

  async fetchById (id: string) : Promise<Question | undefined> {
    const result = await jwtAxios.get(`/question/${id}`)
    return this.transformItem(result.data?.data)
  }

  async store (question: Question) : Promise<Question | undefined> {
    const { data } = this.#prepareRequestBody(question)
    const result = await jwtAxios.post('/question', data)
    return this.transformItem(result.data?.data)
  }

  async update (question: Question) : Promise<Question | undefined> {
    const { data } = this.#prepareRequestBody(question)
    const result = await jwtAxios.put(`/question/${question.id}`, data)
    return this.transformItem(result.data?.data)
  }

  async updateOrder (lectureId: string, ids: string[]) {
    await jwtAxios.post('/question/order', { lecture_id: lectureId, ids })
  }

  async remove (id: string) {
    await jwtAxios.delete(`/question/${id}`)
  }

  transformItem (raw: QuestionResponse) : Question {
    const question: Question = {
      _i: raw._i,
      id: raw.id,
      title: raw.title,
      option0: raw.option0,
      option1: raw.option1,
      option2: raw.option2,
      option3: raw.option3,
      answer: raw.answer,
      explanation: raw.explanation,
      lectureId: raw.lecture_id,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return question
  }
}
