import React from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppEnums'

const UserCreationForm = React.lazy(() => import('./UserCreationForm'))
const UserList = React.lazy(() => import('./UserList'))
const UserProfile = React.lazy(() => import('./UserProfile'))
const UserUpdateForm = React.lazy(() => import('./UserUpdateForm'))

export const userPagesConfig = [
  {
    permittedRole: RoutePermittedRole.userManager,
    path: '/user',
    element: <UserList />
  },
  {
    permittedRole: RoutePermittedRole.userManager,
    path: '/user/:id',
    element: <UserUpdateForm />
  },
  {
    permittedRole: RoutePermittedRole.userManager,
    path: '/user/create',
    element: <UserCreationForm />
  },
  {
    path: '/user/profile',
    element: <UserProfile />
  }
]
