/* eslint-disable @typescript-eslint/no-explicit-any */
export type ListParams = {
  pagination?: {
    page?: number;
    perPage?: number;
  },
  filters?: Record<string, string | number | Array<string | number> | null>;
  sorter?: {
    field: string;
    type: 'asc' | 'desc';
  }[];
}

export type ListResult = {
  data: any[];
  meta: {
    page: {
      current: number;
      last: number;
      perPage: number;
    },
    item: {
      count: number;
      total: number;
    }
  }
}

export class ListService {
  params?: ListParams

  constructor (params?: ListParams) {
    this.params = params
  }

  #getPaginationParams (): string[] {
    const queryParams: string[] = []
    if (this.params?.pagination) {
      const { page, perPage } = this.params.pagination
      if (page) queryParams.push(`page=${page}`)
      if (perPage) queryParams.push(`perPage=${perPage}`)
    }
    return queryParams
  }

  #getFilterParams (): string[] {
    const queryParams: string[] = []
    if (this.params?.filters) {
      for (const key in this.params.filters) {
        if (typeof this.params.filters[key] !== 'undefined' && this.params.filters[key] !== null) {
          const value = this.params.filters[key]
          queryParams.push(`${key}=${Array.isArray(value) ? value.join(',') : value}`)
        }
      }
    }
    return queryParams
  }

  #getSorterParams (): string[] {
    if (this.params?.sorter?.length) {
      const sortFields: string[] = []
      const sortTypes: string[] = []
      this.params.sorter.forEach(sorter => {
        sortFields.push(sorter.field)
        sortTypes.push(sorter.type)
      })
      return [
        `sortFields=${sortFields.join(',')}`,
        `sortTypes=${sortTypes.join(',')}`
      ]
    }
    return []
  }

  getAllParams (): string[] {
    return [
      ...this.#getPaginationParams(),
      ...this.#getFilterParams(),
      ...this.#getSorterParams()
    ]
  }
}
