import type { AnswerProgressRequest, Point, PointResponse, Progress, ProgressRequest, ProgressResponse } from '../../models'
import { jwtAxios } from '../generic'

export class ProgressService {
  async store (progress: ProgressRequest): Promise<Progress | undefined> {
    const { lectureId, ...formData } = progress
    const result = await jwtAxios.put(`/lecture/${lectureId}/progress`, formData)
    return this.transformItem(result.data?.data)
  }

  async storeView (lectureId: string): Promise<Point | undefined> {
    const result = await jwtAxios.post(`/lecture/${lectureId}/viewed`)
    return this.transformPoint(result.data?.data)
  }

  async storeAnswer (progress: AnswerProgressRequest): Promise<Point | undefined> {
    const { lectureId, ...formData } = progress
    const result = await jwtAxios.post(`/lecture/${lectureId}/answered`, formData)
    return this.transformPoint(result.data?.data)
  }

  async storeCompletion (lectureId: string): Promise<Progress | undefined> {
    const result = await jwtAxios.post(`/lecture/${lectureId}/completed`)
    return this.transformItem(result.data?.data)
  }

  async storeQuran (lectureId: string): Promise<Point | undefined> {
    const result = await jwtAxios.post(`/lecture/${lectureId}/quran`)
    return this.transformPoint(result.data?.data)
  }

  transformItem (raw: ProgressResponse): Progress {
    const progress: Progress = {
      _i: raw._i,
      id: raw.id,
      userId: raw.user_id,
      lectureId: raw.lecture_id,
      bookmark: raw.bookmark,
      nCompletions: raw.n_completions,
      nAnswers: raw.n_answers,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return progress
  }

  transformPoint (raw: PointResponse): Point {
    const point: Point = {
      _i: raw._i,
      id: raw.id,
      userId: raw.user_id,
      pointableType: raw.pointable_type,
      pointableId: raw.pointable_id,
      pointableArgs: raw.pointable_args,
      points: raw.points,
      rupiahs: raw.rupiahs,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return point
  }
}
