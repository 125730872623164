import React from 'react'

const Signin = React.lazy(() => import('./Signin'))

export const authPagesConfig = [
  {
    path: '/signin',
    element: <Signin />
  }
]
