import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaChevronDown } from 'react-icons/fa'
import { Avatar, Dropdown, List } from 'antd'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { StringUtils } from '../../../../../utils'
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider'
import { useThemeContext } from '../../../../utility/AppContextProvider/ThemeContextProvider'
import { useAuthMethod, useAuthUser } from '../../../../utility/AuthHooks'

import './index.style.less'

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext()
  const { logout } = useAuthMethod()
  const { user } = useAuthUser()
  const navigate = useNavigate()
  const { isSidebarBgImage, sidebarColorSet } = useSidebarContext()
  const t = StringUtils.useT()

  const getUserAvatar = () => {
    if (user.name) {
      return user.name.charAt(0).toUpperCase()
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase()
    }
  }

  const menu = themeMode => (
    <List className='dropdown-list' style={{ backgroundColor: themeMode === 'dark' ? '#f2ebe9' : 'initial' }}>
      <List.Item onClick={() => navigate('/user/profile')}>{t('user.profile')}</List.Item>
      <List.Item onClick={() => logout()}>{t('user.actions.logout')}</List.Item>
    </List>
  )

  return (
    <>
      {hasColor
        ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light'
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlay={menu(themeMode)}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <Avatar className='cr-user-info-avatar' src={user.avatar?.thumbnailUrl}>{getUserAvatar()}</Avatar>
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light'
                    })}>
                    {user.name}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {t(`user.roles.${user.role}`)}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
          )
        : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light'
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlay={menu(themeMode)}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              <Avatar className='cr-user-info-avatar' src={user.avatar?.thumbnailUrl}>{getUserAvatar()}</Avatar>
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light'
                    })}>
                    {user.name}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {t(`user.roles.${user.role}`)}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
          )}
    </>
  )
}

export default UserInfo

UserInfo.propTypes = {
  hasColor: PropTypes.bool
}
