import React from 'react'

const Dashboard = React.lazy(() => import('./Dashboard'))

export const dashboardPagesConfig = [
  {
    path: '/dashboard',
    element: <Dashboard />
  }
]
