import React from 'react'
import { AiOutlineDashboard, AiOutlineUser } from 'react-icons/ai'
import { FaCoins } from 'react-icons/fa'
import { HiCog, HiOutlineAcademicCap } from 'react-icons/hi'
import { RiHandCoinLine } from 'react-icons/ri'

import { RoutePermittedRole } from '../shared/constants/AppEnums'

const routesConfig = [
  {
    id: 'app',
    messageId: 'menu.app',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        messageId: 'dashboard',
        type: 'item',
        icon: <AiOutlineDashboard />,
        path: '/dashboard'
      },
      {
        id: 'course',
        messageId: 'course',
        type: 'item',
        icon: <HiOutlineAcademicCap />,
        path: '/course',
        allowed: RoutePermittedRole.contentReader
      },
      {
        id: 'point',
        messageId: 'point',
        type: 'item',
        icon: <FaCoins />,
        path: '/point',
        allowed: RoutePermittedRole.contentManager
      },
      {
        id: 'donation',
        messageId: 'donation',
        type: 'item',
        icon: <RiHandCoinLine />,
        path: '/donation',
        allowed: RoutePermittedRole.contentManager
      },
      {
        id: 'user',
        messageId: 'user',
        type: 'item',
        icon: <AiOutlineUser />,
        path: '/user',
        allowed: RoutePermittedRole.userManager
      },
      {
        id: 'config',
        messageId: 'config',
        type: 'item',
        icon: <HiCog />,
        path: '/config',
        allowed: RoutePermittedRole.configManager
      }
    ]
  }
]
export default routesConfig
