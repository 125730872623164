import type { Media, MediaRequest, MediaResponse } from '../../models'
import { jwtAxios } from '../generic'

export class MediaService {
  #prepareFormData (media: MediaRequest) : FormData {
    const formData = new FormData()
    formData.append('type', media.type)
    formData.append('media', media.file)
    formData.append('mediable_id', String(media.mediableId))
    formData.append('mediable_type', String(media.mediableType))
    return formData
  }

  async store (media: MediaRequest) : Promise<Media | undefined> {
    const result = await jwtAxios.post('/media', this.#prepareFormData(media), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return this.transformItem(result.data?.data)
  }

  async update (media: MediaRequest) : Promise<Media | undefined> {
    const result = await jwtAxios.post(`/media/${media.id}?_method=PUT`, this.#prepareFormData(media), {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return this.transformItem(result.data?.data)
  }

  async remove (id: string) {
    await jwtAxios.delete(`/media/${id}`)
  }

  transformItem (raw: MediaResponse) : Media {
    return {
      id: raw.id,
      name: raw.name,
      isExternal: raw.is_external,
      url: raw.url,
      thumbnailUrl: raw.thumbnail_url,
      fileType: raw.file_type,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
  }
}
