import enMessages from '../locales/en_US.json'
import idMessages from '../locales/id_ID.json'
import idID from 'antd/lib/locale/id_ID'

const IdLang = {
  messages: {
    ...enMessages,
    ...idMessages
  },
  antLocale: idID,
  locale: 'id-ID'
}
export default IdLang
