import { useQuery, useQueryClient } from 'react-query'

import type { User, UserProgress } from '../../models'
import type { ListParams } from '../generic'
import { UserService } from './service'
import type { UsersListResult } from './service'

export const QN_USERS_LIST = 'users-list'
export const QN_USER_DETAIL = 'user-detail'
export const QN_USER_PROGRESS = 'user-progress'

export const useUsersList = (params?: ListParams) => {
  const service = new UserService(params)
  return useQuery<UsersListResult | undefined, Error>(
    [QN_USERS_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}

export const useUser = (id: string) => {
  const service = new UserService()
  return useQuery<User | undefined, Error>(
    [QN_USER_DETAIL, id],
    () => service.fetchById(id),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useUserProgress = () => {
  const service = new UserService()
  return useQuery<UserProgress | undefined, Error>(
    QN_USER_PROGRESS,
    () => service.fetchProgress(),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useUserService = () => {
  const service = new UserService()
  const queryClient = useQueryClient()

  const store = async (user: User) : Promise<User | undefined> => {
    const newUser = await service.store(user)
    await queryClient.invalidateQueries(QN_USERS_LIST)
    return newUser
  }

  const update = async (user: User) : Promise<User | undefined> => {
    const updatedUser = await service.update(user)
    setTimeout(async () => {
      await queryClient.invalidateQueries([QN_USER_DETAIL, user.id])
    }, 500)
    await queryClient.invalidateQueries(QN_USERS_LIST)
    return updatedUser
  }

  const storeOrUpdate = async (user: User) : Promise<User | undefined> => {
    return user.id ? update(user) : store(user)
  }

  const remove = async (id: string) => {
    await service.remove(id)
    queryClient.invalidateQueries(QN_USERS_LIST)
  }

  return { store, update, storeOrUpdate, remove }
}
