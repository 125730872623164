import { useQueryClient } from 'react-query'

import { MediableType } from '../../models'
import type { Media, MediaRequest } from '../../models'
import { QN_COURSE_DETAIL } from '../course'
import { QN_USER_DETAIL } from '../user'
import { MediaService } from './service'

const qnMapper: Record<string, string> = {
  [MediableType.COURSE]: QN_COURSE_DETAIL,
  [MediableType.USER]: QN_USER_DETAIL
}

export const useMediaService = () => {
  const service = new MediaService()
  const queryClient = useQueryClient()

  const store = async (media: MediaRequest) : Promise<Media | undefined> => {
    const newMedia = await service.store(media)
    setTimeout(async () => {
      if (media.mediableType && media.mediableId) {
        await queryClient.invalidateQueries([qnMapper[media.mediableType], media.mediableId])
      }
    }, 500)
    return newMedia
  }

  const update = async (media: MediaRequest) : Promise<Media | undefined> => {
    const updatedUser = await service.update(media)
    setTimeout(async () => {
      if (media.mediableType && media.mediableId) {
        await queryClient.invalidateQueries([qnMapper[media.mediableType], media.mediableId])
      }
    }, 500)
    return updatedUser
  }

  const storeOrUpdate = async (media: MediaRequest) : Promise<Media | undefined> => {
    return media.id ? update(media) : store(media)
  }

  const remove = async (id: string) => {
    await service.remove(id)
  }

  return { store, update, storeOrUpdate, remove }
}
