import { useQuery, useQueryClient } from 'react-query'

import type { Config } from '../../models'
import { ConfigService } from './service'

export const QN_CONFIG = 'config'

export const useConfig = () => {
  const service = new ConfigService()
  return useQuery<Config | undefined, Error>(
    QN_CONFIG,
    () => service.fetchAll(),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useConfigService = () => {
  const service = new ConfigService()
  const queryClient = useQueryClient()

  const store = async (config: Config): Promise<Config | undefined> => {
    const newConfig = await service.store(config)
    await queryClient.invalidateQueries(QN_CONFIG)
    return newConfig
  }

  return { store }
}
