// API
export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'
export const SHOW_MESSAGE = 'show_message'
export const HIDE_MESSAGE = 'hide_message'
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer'
export const UPDATING_CONTENT = 'updating_content'

// APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed'
export const SET_INITIAL_PATH = 'set_initial_path'
