import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { FiMoreVertical } from 'react-icons/fi'
import { Dropdown, Layout, Menu } from 'antd'
import PropTypes from 'prop-types'

import { StringUtils } from '../../../../utils'
import AppLanguageSwitcher from '../../AppLanguageSwitcher'
import AppLogo from '../components/AppLogo'

import './index.style.less'

const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const t = StringUtils.useT()
  const { Header } = Layout
  const menuMobile = (
    <Menu>
      <AppLanguageSwitcher />
    </Menu>
  )

  return (
    <Header className='app-header'>
      <a className='trigger' onClick={() => onToggleSidebar(!isCollapsed)}>
        <AiOutlineMenu />
      </a>
      <AppLogo />
      <div style={{ flexGrow: 1 }}>{t('app.slogan')}</div>
      <div className='app-header-sectionDesktop'>
        <AppLanguageSwitcher />
      </div>
      <div className='app-header-section-mobile'>
        <Dropdown overlay={menuMobile} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  )
}

export default AppHeader

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool
}
