import { authRole } from '../../../shared/constants/AppEnums'

const roleMapper = ['superadmin', 'admin', 'user']

export const getUserFromJwtAuth = (user) => {
  if (user) {
    return {
      ...user,
      routeRole: authRole[roleMapper[user.role]]
    }
  }
  return user
}
