import { useQuery } from 'react-query'

import type { ListParams } from '../generic'
import { PointService } from './service'
import type { PointsListResult } from './service'

export const QN_POINTS_LIST = 'points-list'

export const usePointsList = (params?: ListParams) => {
  const service = new PointService(params)
  return useQuery<PointsListResult | undefined, Error>(
    [QN_POINTS_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}
