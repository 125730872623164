import { useQuery, useQueryClient } from 'react-query'

import type { Course } from '../../models'
import type { ListParams } from '../generic'
import { CourseService } from './service'
import type { CoursesListResult } from './service'

export const QN_COURSES_LIST = 'courses-list'
export const QN_COURSE_DETAIL = 'course-detail'

export const useCoursesList = (params?: ListParams) => {
  const service = new CourseService(params)
  return useQuery<CoursesListResult | undefined, Error>(
    [QN_COURSES_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}

export const useCourse = (id: string) => {
  const service = new CourseService()
  return useQuery<Course | undefined, Error>(
    [QN_COURSE_DETAIL, id],
    () => service.fetchById(id),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useCourseService = () => {
  const service = new CourseService()
  const queryClient = useQueryClient()

  const store = async (course: Course) : Promise<Course | undefined> => {
    const newCourse = await service.store(course)
    await queryClient.invalidateQueries(QN_COURSES_LIST)
    return newCourse
  }

  const update = async (course: Course) : Promise<Course | undefined> => {
    const updatedCourse = await service.update(course)
    setTimeout(async () => {
      await queryClient.invalidateQueries([QN_COURSE_DETAIL, course.id])
    }, 500)
    await queryClient.invalidateQueries(QN_COURSES_LIST)
    return updatedCourse
  }

  const storeOrUpdate = async (course: Course) : Promise<Course | undefined> => {
    return course.id ? update(course) : store(course)
  }

  const remove = async (id: string) => {
    await service.remove(id)
    queryClient.invalidateQueries(QN_COURSES_LIST)
  }

  return { store, update, storeOrUpdate, remove }
}
