import React from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppEnums'

const ConfigForm = React.lazy(() => import('./ConfigForm'))

export const configPagesConfig = [
  {
    permittedRole: RoutePermittedRole.configManager,
    path: '/config',
    element: <ConfigForm />
  }
]
