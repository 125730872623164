/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Lecture, LectureResponse } from './lecture'
import type { Media, MediaResponse } from './media'

export enum UserType {
  ADMIN = 0,
  RESIDENT_STUDENT = 1,
  NON_RESIDENT_STUDENT = 2,
  GUEST = 3
}

export type User = {
  _i?: number
  id?: string
  name: string
  username: string
  rfid: string
  role: number
  type: UserType
  sex: string
  visits?: number
  points?: number
  rupiahs?: number
  avatar?: Media
  createdAt?: Date
  updatedAt?: Date
}

export type UserLoginRequest = {
  username: string
  password: string
}

export type UserLoginResponse = {
  token: string
  user: User
}

export type UserPasswordRequest = {
  oldPassword: string
  newPassword: string
}

export type UserResponse = {
  _i?: number
  id: string
  name: string
  username: string
  rfid: string
  role: number
  type: UserType
  sex: string
  visits: number
  points: number
  rupiahs: number
  avatar?: MediaResponse
  created_at: Date
  updated_at: Date
}

export type UserProgress = {
  all: {
    points: number
    rupiahs: number
  }
  today: {
    points: number
    rupiahs: number
  }
  courses: Record<string, number>[],
  challenge?: Lecture
}

export type UserProgressResponse = {
  all: {
    points: number
    rupiahs: number
  }
  today: {
    points: number
    rupiahs: number
  }
  courses: Record<string, number>[],
  challenge?: LectureResponse
}
