import type { Lecture, LectureResponse, LectureWithContentRequest, Media, MediaRequest } from '../../models'
import { ListParams, ListService, jwtAxios } from '../generic'
import type { ListResult } from '../generic'
import { MediaService } from '../media'
import { ProgressService } from '../progress'

export interface LecturesListResult extends ListResult {
  data: Lecture[];
}

export class LectureService extends ListService {
  result?: LecturesListResult
  mediaService: MediaService
  progressService: ProgressService

  constructor (params?: ListParams) {
    super(params)
    this.mediaService = new MediaService()
    this.progressService = new ProgressService()
  }

  #prepareRequestBody (lecture: LectureWithContentRequest) {
    const { courseId, content, extContent, ...lectureForm } = lecture
    return {
      content,
      data: {
        ...lectureForm,
        course_id: courseId,
        content_url: extContent?.url,
        content_thumbnail_url: extContent?.thumbnailUrl
      }
    }
  }

  #storeOrUpdateMedia (media: MediaRequest) : Promise<Media | undefined> {
    return media?.id ? this.mediaService.update(media) : this.mediaService.store(media)
  }

  async fetchList () : Promise<LecturesListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/lecture?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map((item : LectureResponse) => this.transformItem(item))
      return retval
    }
  }

  async fetchById (id: string) : Promise<Lecture | undefined> {
    const result = await jwtAxios.get(`/lecture/${id}`)
    return this.transformItem(result.data?.data)
  }

  async store (lecture: LectureWithContentRequest) : Promise<Lecture | undefined> {
    const { content, data } = this.#prepareRequestBody(lecture)
    const result = await jwtAxios.post('/lecture', data)
    const retval = this.transformItem(result.data?.data)
    if (content) {
      retval.content = await this.#storeOrUpdateMedia(content)
    }
    return retval
  }

  async update (lecture: LectureWithContentRequest) : Promise<Lecture | undefined> {
    const { content, data } = this.#prepareRequestBody(lecture)
    const result = await jwtAxios.put(`/lecture/${lecture.id}`, data)
    const retval = this.transformItem(result.data?.data)
    if (content) {
      retval.content = await this.#storeOrUpdateMedia(content)
    }
    return retval
  }

  async updateOrder (courseId: string, ids: string[]) {
    await jwtAxios.post('/lecture/order', { course_id: courseId, ids })
  }

  async remove (id: string) {
    await jwtAxios.delete(`/lecture/${id}`)
  }

  transformItem (raw: LectureResponse) : Lecture {
    const lecture: Lecture = {
      _i: raw._i,
      id: raw.id,
      title: raw.title,
      description: raw.description,
      level: raw.level,
      courseId: raw.course_id,
      content: raw.content ? this.mediaService.transformItem(raw.content) : undefined,
      progress: raw.progress ? this.progressService.transformItem(raw.progress) : undefined,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return lecture
  }
}
