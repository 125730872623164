import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

import { AppAnimateGroup, AppPageMetadata } from '../../../@crema/core'
import { ReactComponent as Logo } from '../../../assets/icon/500.svg'
import { StringUtils } from '../../../utils'

import '../index.style.less'

const Error500 = () => {
  const navigate = useNavigate()
  const t = StringUtils.useT()

  const onGoBackHome = () => {
    navigate('/')
  }

  return (
    <AppAnimateGroup type='bottom'>
      <AppPageMetadata title='Server Error' />
      <div className='error-container' key='a'>
        <div className='error-img'>
          <Logo />
        </div>
        <div className='error-content'>
          <h3>{t('error.500')}</h3>
          <div className='error-para'>
            <p className='mb-0'>{t('error.500.message1')}</p>
            <p className='mb-0'>{t('error.500.message2')}</p>
          </div>
          <Button type='primary' className='error-btn' onClick={onGoBackHome}>
            {t('app.actions.goBackHome')}
          </Button>
        </div>
      </div>
    </AppAnimateGroup>
  )
}

export default Error500
