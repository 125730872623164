const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'indonesian',
    locale: 'id',
    name: 'Indonesia',
    icon: 'id'
  }
]
export default languageData
