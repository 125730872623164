import { useQuery, useQueryClient } from 'react-query'

import type { Donation } from '../../models'
import type { ListParams } from '../generic'
import { DonationService } from './service'
import type { DonationsListResult } from './service'

export const QN_DONATIONS_LIST = 'donations-list'
export const QN_DONATION_DETAIL = 'donation-detail'

export const useDonationsList = (params?: ListParams) => {
  const service = new DonationService(params)
  return useQuery<DonationsListResult | undefined, Error>(
    [QN_DONATIONS_LIST, params],
    () => service.fetchList(),
    {
      staleTime: 300000
    }
  )
}

export const useDonation = (id: string) => {
  const service = new DonationService()
  return useQuery<Donation | undefined, Error>(
    [QN_DONATION_DETAIL, id],
    () => service.fetchById(id),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}

export const useDonationService = () => {
  const service = new DonationService()
  const queryClient = useQueryClient()

  const store = async (donation: Donation) : Promise<Donation | undefined> => {
    const newDonation = await service.store(donation)
    await queryClient.invalidateQueries(QN_DONATIONS_LIST)
    return newDonation
  }

  const update = async (donation: Donation) : Promise<Donation | undefined> => {
    const updatedDonation = await service.update(donation)
    setTimeout(async () => {
      await queryClient.invalidateQueries([QN_DONATION_DETAIL, donation.id])
    }, 500)
    await queryClient.invalidateQueries(QN_DONATIONS_LIST)
    return updatedDonation
  }

  const storeOrUpdate = async (donation: Donation) : Promise<Donation | undefined> => {
    return donation.id ? update(donation) : store(donation)
  }

  const remove = async (id: string) => {
    await service.remove(id)
    queryClient.invalidateQueries(QN_DONATIONS_LIST)
  }

  return { store, update, storeOrUpdate, remove }
}
