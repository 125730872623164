import React from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppEnums'

const DonationCreationForm = React.lazy(() => import('./DonationCreationForm'))
const DonationDetail = React.lazy(() => import('./DonationDetail'))
const DonationList = React.lazy(() => import('./DonationList'))

export const donationPagesConfig = [
  {
    permittedRole: RoutePermittedRole.contentReader,
    path: '/donation',
    element: <DonationList />
  },
  {
    permittedRole: RoutePermittedRole.contentReader,
    path: '/donation/:id',
    element: <DonationDetail />
  },
  {
    permittedRole: RoutePermittedRole.contentManager,
    path: '/donation/create',
    element: <DonationCreationForm />
  }
]
