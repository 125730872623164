import React from 'react'

import { initialUrl } from '../shared/constants/AppConst'
import { authPagesConfig } from './auth'
import { configPagesConfig } from './config'
import { coursePagesConfig } from './course'
import { dashboardPagesConfig } from './dashboard'
import { donationPagesConfig } from './donation'
import { errorPagesConfigs } from './errorPages'
import Error403 from './errorPages/Error403'
import Error404 from './errorPages/Error404'
import { pointPagesConfig } from './point'
import { userPagesConfig } from './user'

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...configPagesConfig,
    ...coursePagesConfig,
    ...dashboardPagesConfig,
    ...donationPagesConfig,
    ...pointPagesConfig,
    ...userPagesConfig
  ]
}

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authPagesConfig
}

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />
    }
  ])
}

export { authorizedStructure, unAuthorizedStructure, anonymousStructure }
