import axios from 'axios'

let dispatchLogout

const normalizeServerError = errors => {
  if (typeof errors === 'string') return Error(`server.${errors}`)
  const messages = Object.values(errors).map(item => typeof item === 'string' ? item : item.join(' ')).join(' ')
  return Error(messages, { cause: errors })
}

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json'
  }
})

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.config?.url === '/auth/logout') return Promise.resolve(true)
    if (err.response) {
      if (err.response.data.errors === 'Invalid token' && dispatchLogout) {
        dispatchLogout()
      }
      if (err.response.data.errors) {
        return Promise.reject(normalizeServerError(err.response.data.errors))
      }
    }
    return Promise.reject(err)
  }
)

export const setAuthToken = (token, onLogout) => {
  if (onLogout && !dispatchLogout) dispatchLogout = onLogout
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
  } else {
    delete jwtAxios.defaults.headers.common.Authorization
    localStorage.removeItem('token')
  }
}

export default jwtAxios
