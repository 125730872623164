import type { User, UserLoginRequest, UserLoginResponse, UserPasswordRequest } from '../../models'
import { AuthService } from './service'

export const useAuthService = () => {
  const service = new AuthService()

  const login = (form: UserLoginRequest) : Promise<UserLoginResponse | undefined> => service.login(form)
  const fetchInfo = () : Promise<User | undefined> => service.fetchInfo()
  const changePassword = (form: UserPasswordRequest) => service.changePassword(form)
  const logout = () => service.logout()

  return { login, fetchInfo, changePassword, logout }
}
