import { useQuery } from 'react-query'

import type { DashboardSummary } from '../../models'
import { DashboardService } from './service'

export const QN_DASHBOARD_SUMMARY = 'dashboard_summary'

export const useDashboardSummary = () => {
  const service = new DashboardService()
  return useQuery<DashboardSummary[] | undefined, Error>(
    QN_DASHBOARD_SUMMARY,
    () => service.summary(),
    {
      staleTime: 300000,
      retry: 1
    }
  )
}
