import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import './shared/styles/crema.less'
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes
} from './@crema'
import configureStore from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import JWTAuthProvider from './@crema/services/auth/jwt-auth/JWTAuthProvider'

const queryClient = new QueryClient()
const store = configureStore()

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <JWTAuthProvider>
                <AuthRoutes>
                  <AppLayout />
                </AuthRoutes>
              </JWTAuthProvider>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </QueryClientProvider>
    </Provider>
  </AppContextProvider>
)

export default App
