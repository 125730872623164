import React from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppEnums'

const PointList = React.lazy(() => import('./PointList'))

export const pointPagesConfig = [
  {
    permittedRole: RoutePermittedRole.contentReader,
    path: '/point',
    element: <PointList />
  }
]
