import React from 'react'

import { RoutePermittedRole } from '../../shared/constants/AppEnums'

const CourseCreationForm = React.lazy(() => import('./CourseCreationForm'))
const CourseDetail = React.lazy(() => import('./CourseDetail'))
const CourseList = React.lazy(() => import('./CourseList'))
const LectureDetail = React.lazy(() => import('./LectureDetail'))

export const coursePagesConfig = [
  {
    permittedRole: RoutePermittedRole.contentReader,
    path: '/course',
    element: <CourseList />
  },
  {
    permittedRole: RoutePermittedRole.contentReader,
    path: '/course/:id',
    element: <CourseDetail />
  },
  {
    permittedRole: RoutePermittedRole.contentManager,
    path: '/course/:id/edit',
    element: <CourseDetail isEdit={true} />
  },
  {
    permittedRole: RoutePermittedRole.contentManager,
    path: '/course/:courseId/lecture/:id',
    element: <LectureDetail />
  },
  {
    permittedRole: RoutePermittedRole.contentManager,
    path: '/course/create',
    element: <CourseCreationForm />
  }
]
