/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Lecture, LectureResponse } from './lecture'
import { Question, QuestionResponse } from './question'
import { User, UserResponse } from './user'

export enum PointableType {
  LECTURE = 'lectures',
  QUESTION = 'questions'
}

export type Point = {
  _i?: number
  id: string
  userId: string
  pointableType: PointableType
  pointableId: string
  pointableArgs: unknown
  points: number
  rupiahs: number
  user?: User
  pointable?: Lecture | Question
  createdAt?: Date
  updatedAt?: Date
}

export type PointResponse = {
  _i?: number
  id: string
  user_id: string
  pointable_type: PointableType
  pointable_id: string
  pointable_args: unknown
  points: number
  rupiahs: number
  user?: UserResponse
  pointable?: LectureResponse | QuestionResponse
  created_at: Date
  updated_at: Date
}
