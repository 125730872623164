import type { Course, CourseResponse } from '../../models'
import { ListService, jwtAxios } from '../generic'
import { MediaService } from '../media'
import type { ListResult } from '../generic'

export interface CoursesListResult extends ListResult {
  data: Course[];
}

export class CourseService extends ListService {
  result?: CoursesListResult

  async fetchList () : Promise<CoursesListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/course?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map((item : CourseResponse) => this.transformItem(item))
      return retval
    }
  }

  async fetchById (id: string) : Promise<Course | undefined> {
    const result = await jwtAxios.get(`/course/${id}`)
    return this.transformItem(result.data?.data)
  }

  async store (course: Course) : Promise<Course | undefined> {
    const result = await jwtAxios.post('/course', course)
    return this.transformItem(result.data?.data)
  }

  async update (course: Course) : Promise<Course | undefined> {
    const result = await jwtAxios.put(`/course/${course.id}`, course)
    return this.transformItem(result.data?.data)
  }

  async remove (id: string) {
    await jwtAxios.delete(`/course/${id}`)
  }

  transformItem (raw: CourseResponse) : Course {
    const mediaService = new MediaService()
    const course: Course = {
      _i: raw._i,
      id: raw.id,
      title: raw.title,
      description: raw.description,
      level: raw.level,
      isSingleLecture: raw.is_single_lecture,
      nLectures: raw.n_lectures,
      banner: raw.banner ? mediaService.transformItem(raw.banner) : undefined,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return course
  }
}
