import { Lecture, LectureResponse, Point, PointableType, PointResponse, Question, QuestionResponse } from '../../models'
import { ListParams, ListService, jwtAxios } from '../generic'
import { LectureService } from '../lecture'
import { QuestionService } from '../question'
import { UserService } from '../user'
import type { ListResult } from '../generic'

export interface PointsListResult extends ListResult {
  data: Point[];
}

export class PointService extends ListService {
  result?: PointsListResult
  lectureService: LectureService
  questionService: QuestionService
  userService: UserService

  constructor (params?: ListParams) {
    super(params)
    this.lectureService = new LectureService()
    this.questionService = new QuestionService()
    this.userService = new UserService()
  }

  async fetchList () : Promise<PointsListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/point?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map((item : PointResponse) => this.transformItem(item))
      return retval
    }
  }

  #transformPointable (raw: PointResponse): Lecture | Question | undefined {
    if (!raw.pointable) return undefined
    if (raw.pointable_type === PointableType.LECTURE) return this.lectureService.transformItem(raw.pointable as LectureResponse)
    if (raw.pointable_type === PointableType.QUESTION) return this.questionService.transformItem(raw.pointable as QuestionResponse)
  }

  transformItem (raw: PointResponse) : Point {
    const point: Point = {
      _i: raw._i,
      id: raw.id,
      userId: raw.user_id,
      pointableType: raw.pointable_type,
      pointableId: raw.pointable_id,
      pointableArgs: raw.pointable_args,
      points: raw.points,
      rupiahs: raw.rupiahs,
      user: raw.user ? this.userService.transformItem(raw.user) : undefined,
      pointable: this.#transformPointable(raw),
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return point
  }
}
