import type { Donation, DonationResponse } from '../../models'
import { ListService, jwtAxios } from '../generic'
import { MediaService } from '../media'
import type { ListResult } from '../generic'

export interface DonationsListResult extends ListResult {
  data: Donation[];
}

export class DonationService extends ListService {
  result?: DonationsListResult

  async fetchList () : Promise<DonationsListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/donation?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map((item : DonationResponse) => this.transformItem(item))
      return retval
    }
  }

  async fetchById (id: string) : Promise<Donation | undefined> {
    const result = await jwtAxios.get(`/donation/${id}`)
    return this.transformItem(result.data?.data)
  }

  async store (donation: Donation) : Promise<Donation | undefined> {
    const result = await jwtAxios.post('/donation', donation)
    return this.transformItem(result.data?.data)
  }

  async update (donation: Donation) : Promise<Donation | undefined> {
    const result = await jwtAxios.put(`/donation/${donation.id}`, donation)
    return this.transformItem(result.data?.data)
  }

  async remove (id: string) {
    await jwtAxios.delete(`/donation/${id}`)
  }

  transformItem (raw: DonationResponse) : Donation {
    const mediaService = new MediaService()
    const donation: Donation = {
      _i: raw._i,
      id: raw.id,
      title: raw.title,
      rupiahs: raw.rupiahs,
      description: raw.description,
      content: raw.content ? mediaService.transformItem(raw.content) : undefined,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return donation
  }
}
