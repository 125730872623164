/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
export enum MediaType {
  AVATAR = 'avatar',
  BANNER = 'banner',
  CONTENT = 'content'
}

export enum MediableType {
  USER = 'users',
  COURSE = 'courses',
  LECTURE = 'lectures'
}

export type Media = {
  _i?: number
  id: string
  name: string
  isExternal: boolean
  url: string
  thumbnailUrl: string
  fileType: string
  createdAt: Date
  updatedAt: Date
}

export type MediaRequest = {
  id?: string
  file: File
  type: MediaType
  mediableId?: string
  mediableType?: MediableType
}

export type ExtMediaRequest = {
  id?: string
  url?: string
  thumbnailUrl?: string
  type?: MediaType
  mediableId?: string
  mediableType?: MediableType
}

export type MediaResponse = {
  id: string
  name: string
  is_external: boolean
  url: string
  thumbnail_url: string
  file_type: string
  created_at: Date
  updated_at: Date
}
