import React from 'react'
import { Layout } from 'antd'
import './AppFooter.style.less'
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider'

const { Footer } = Layout

const AppFooter = () => {
  const { footer } = useLayoutContext()

  if (footer) {
    return (
      <Footer className='app-main-footer border-top py-2'>
        <p>Copyright © 2022</p>
        <div className='footer-btn-view'>
          <span>2.0.0</span>
        </div>
      </Footer>
    )
  }
  return null
}

export default AppFooter
