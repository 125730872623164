import type { User, UserProgress, UserProgressResponse, UserResponse } from '../../models'
import { ListService, jwtAxios, transformTimestamps } from '../generic'
import { LectureService } from '../lecture'
import { MediaService } from '../media'
import type { ListResult } from '../generic'

export interface UsersListResult extends ListResult {
  data: User[];
}

export class UserService extends ListService {
  result?: UsersListResult

  async fetchList () : Promise<UsersListResult | undefined> {
    const queryParams = this.params ? this.getAllParams().join('&') : ''
    const result = await jwtAxios.get(`/user?${queryParams}`)
    if (result?.data?.data?.length) {
      const retval = result.data
      retval.data = result.data.data.map(transformTimestamps)
      return retval
    }
  }

  async fetchById (id: string) : Promise<User | undefined> {
    const result = await jwtAxios.get(`/user/${id}`)
    return this.transformItem(result.data?.data)
  }

  async fetchProgress () : Promise<UserProgress | undefined> {
    const result = await jwtAxios.get('/user/progress')
    return this.transformProgress(result.data?.data)
  }

  async store (user: User) : Promise<User | undefined> {
    const result = await jwtAxios.post('/user', user)
    return this.transformItem(result.data?.data)
  }

  async update (user: User) : Promise<User | undefined> {
    const result = await jwtAxios.put(`/user/${user.id}`, user)
    return this.transformItem(result.data?.data)
  }

  async remove (id: string) {
    await jwtAxios.delete(`/user/${id}`)
  }

  transformItem (raw: UserResponse) : User {
    const mediaService = new MediaService()
    const user: User = {
      id: raw.id,
      name: raw.name,
      username: raw.username,
      rfid: raw.rfid,
      role: raw.role,
      type: raw.type,
      sex: raw.sex,
      visits: raw.visits,
      points: raw.points,
      avatar: raw.avatar ? mediaService.transformItem(raw.avatar) : undefined,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at
    }
    return user
  }

  transformProgress (raw: UserProgressResponse): UserProgress {
    const lectureService = new LectureService()
    const { challenge, ...rest } = raw
    return {
      challenge: challenge ? lectureService.transformItem(challenge) : undefined,
      ...rest
    }
  }
}
